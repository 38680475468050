

.list {
    display: block;
    margin-left:100px;
    margin-top: 5rem;
    min-height: 40vh;
    position: fixed;
}


.list ul {
    position: relative;
    transform: skewY(-15deg);

}

.list li {
    position: relative;
    list-style: none;
    width: 200px;
    background: linear-gradient(#570A57,#A91079);
    padding: 15px;
    transition: 0.5s;
    
    
}

.list li:hover {
    background: linear-gradient(#F806CC,transparent);
    transition: 0.5s;
    transform: translateX(40px);
    margin-top: 40px;
    margin-bottom: 40px;
    /* z-index: 6; */
}

.zid1 {
    z-index: 1;
}

.zid2 {
    z-index: 2;
}

.zid3 {
    z-index: 3;
}

.zid4 {
    z-index: 4;
}

.zid5 {
    z-index: 5;
}


.list li::before {
    content: '';
    position: absolute;
    top: 0;
    left: -40px;
    width: 40px;
    height: 100%;
    background: linear-gradient(#570A57,#A91079);
    transform-origin: right;
    transform: skewY(45deg);
    transition: 0.5s;
    border-radius: 0;
}

.list li:hover::before {
    background: linear-gradient(#F806CC,transparent);
}

.list li::after {
    content: '';
    position: absolute;
    top: -40px;
    left: 0;
    background: #570A57;
    height: 40px;
    width: 100%;
    transform-origin: bottom;
    transform: skewX(45deg);
    transition: 0.5s;
    border-radius: 0;
}

.list li:hover::after {
    background: #F806CC;
}

/* .list li:last-child::after {
    box-shadow: -120px 120px 20px rgb(43, 43, 43);
} */


.list h5 {
    color: rgb(158, 158, 158);
    display: block;
    letter-spacing: 0.05em;
    transition: 0.5s;
}

.list li:hover h5 {
    color: #fff;
}

