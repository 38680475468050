.heading > h2 {
    margin-bottom: 10vh;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
}



.heading > h5 {
    margin-top: 10vh;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
}





/* ***************** Media Queries (small Devices)********** */

@media screen and (max-width: 600px) {
    .list {
        position: relative;
    }
}


/* ***************** Media Queries (medium Devices)********** */

@media screen and (max-width: 1024px) {
    .list {
        position: relative;
    }
}
