.contact__head {
    display: grid;
    margin-top: 30px;
    align-items: center;
    justify-items: center;
    margin-bottom: 5rem;
}

.contact__head {
    color: white;
    animation: contact 1.5s linear infinite alternate;
}

@keyframes contact {
    0% {
        text-shadow: 0 0 8px #F806CC;
    }

    100% {
        text-shadow: 0 0 15px white;
    }
    
}

.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;

}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: orange;
    padding: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: 0.4s ease;
}

.contact__option__icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.style__email {
    color: rgb(177, 41, 0);
}

.style__insta {
    color: rgb(255, 0, 119);
}

.style__wapp {
    color: rgb(30, 128, 0);
}



.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* **************** FORM ************* */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: linear-gradient(180deg,rgba(188, 57, 228, 0.205), transparent);
    border: 2px solid orange;
    resize: none;
    color: white;
}


/* ***************** Media Queries (Medium Devices)********** */

@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* ***************** Media Queries (small Devices)********** */

@media screen and (max-width: 600px) {
    .container.contact__container {
        width: 90%;
    }
}