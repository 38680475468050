@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #2E0249;
    --color-bg-variant: #570A57;
    --color-primary: #F806CC;
    --color-primary-variant: #A91079;
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-ms: 90%;
    
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/bg.png);
}

/* ************** GENERAL STYLE ********** */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

/* section {
    margin-bottom: 8rem;
    height: 100vh;
} */

section > h2, section > h5 {
    text-align: center;
    color: white;
}

section > h2 {
    color: white;
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: white;
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

/* *************My Button*************** */

.btn6 {
    
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.btn6:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.btn6:active {
    color: transparent
}

.btn6:active:after {
    background: transparent;
}

.btn6:hover:before {
    opacity: 1;
}

.btn6:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}
/* *************************** */

/* *************rgb effect*************** */

.rgb {
    
    border: none;
    outline: none;
    color: #fff;
    background: rgb(0, 0, 0);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.rgb:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    
}

.rgb:active {
    color: transparent;
    
}

.rgb:active:after {
    /* background: transparent; */
}

.rgb:hover:before {
    opacity: 1;
    
}

.rgb:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
    
}
/* *************************** */


/* *************Navegb effect*************** */

.navrgb {
    
    border: none;
    outline: none;
    color: #fff;
    background: rgb(0, 0, 0);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.navrgb:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    
}

.navrgb:active {
    color: transparent;
    
}

.navrgb:active:after {
    /* background: transparent; */
}

.navrgb:hover:before {
    /* opacity: 1; */
    
}

.navrgb:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
    
}
/* *************************** */



img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ***************** Media Queries (Medium Devices)********** */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }
    section {
        margin-top: 6rem;
    }
    
}

/* ***************** Media Queries (Small Devices)********** */

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }
    section > h2 {
        margin-top: 2rem;
    }
    
}


