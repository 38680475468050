
footer {
    background: linear-gradient(#2E0249,transparent);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a {
    color: rgb(255, 255, 255);
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
    padding: 0.4rem;
    border-radius: 3rem;
}

.footer__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer__socials {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer__socials a {
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer__copyright {
    margin-bottom: 4rem;
    color: rgb(248, 219, 255);
}



/* ***************** Media Queries (small Devices)********** */

@media screen and (max-width: 600px) {
    .footer__links {
        flex-direction: column;
        gap: 1.5rem;
    }

    .footer__socials {
        margin-bottom: 2.6rem
    }

}


/* ******* WAVE EFFECT ****** */
/* 
.wave {
    display: flex;
    background: url(../../assets/wave.png);
    width: 100%;
    height: 143px;
    margin-top: 10rem;
    margin-bottom: -7rem;
    opacity: 100%;
    animation: wave 10s linear infinite;
    
}

.wave::before {
    content: '';
    width: 100%;
    height: 143px;
    background: url(../../assets/wave.png);
    position: absolute;
    
    opacity: 40%;
    animation: wave-reverse 10s linear infinite;
}

.wave::after {
    content: '';
    
    width: 100%;
    height: 143px;
    background: url(../../assets/wave.png);
    position: absolute;
    opacity: 60%;
    animation-delay: -5s;
    animation: wave 20s linear infinite;
}



@keyframes wave {
    0% {
        background-position: 0px;
    }

    100% {
        background-position: 1360px; 
    }
    
}

@keyframes wave-reverse {
    0% {
        background-position: 1360px;
    }

    100% {
        background-position: 0px; 
    }
    
} */
